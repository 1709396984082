
import Header from './components/Header';
import Maps from './components/Maps';

import AOS from "aos";
import 'aos/dist/aos.css';
import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Swiper from "swiper";
import 'swiper/css/swiper.min.css';  

import {TweenMax} from "gsap/TweenMax";
import fancybox from "@fancyapps/fancybox";
import '@fancyapps/fancybox/dist/jquery.fancybox.min.css';
import './external/intl/intlTelInput-jquery.min.js';

export default new (class App {
  constructor() {
    this.setDomMap();
    this.previousScroll = 0;

    // dom ready shorthand
    $(() => {
      this.domReady();
      // aos - Start
      AOS.init({
          disable : 'tablet',
          once: true,
      });
    });
  }

  domReady = () => {
    this.initComponents();
    this.handleUserAgent();
    this.windowResize();
    this.bindEvents();
    this.handleSplashScreen();
    this.homepageHero();
    this.timelistSlider();
    this.mouseMoveMax();
    this.productAccordian();
    this.productSlider();
    this.megaMenu();
    this.productDetailSlider();
    this.clientSlider();
  };

  homepageHero = () => {
      var time = 2;
      var $bar,
          $slick,
          isPause,
          tick,
          percentTime;
      
      $slick = $('.hslider');
      $slick.slick({
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          draggable:false,
          rows: 0,
          arrows: true,
          speed: 700,
          autoplay: false,
          autoplaySpeed: 8000,
          dots:false,
          fade:true,
      });
      var $status = $('.counter');
      $slick.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
        //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
        var i = (currentSlide ? currentSlide : 0) + 1;
        $status.text('0'+i);
      });
      
      $bar = $('.slider-progress .progressb');
     // $barRound = $('.progress');
      
      // $('.slider-wrapper').on({
      //   mouseenter: function() {
      //     isPause = true;
      //   },
      //   mouseleave: function() {
      //     isPause = false;
      //   }
      // })

      function startProgressbar() {
        resetProgressbar();
        percentTime = 0;
        isPause = false;
        tick = setInterval(interval, 30);
      }
      var $rbar = $('.progressb circle');
      var rlen = 2 * Math.PI * $rbar.attr('r');
      function interval() {
        
        percentTime += 1 / (time + 0.1);
        $bar.css({
          width: percentTime + '%'
        });
        $rbar.css({
          'stroke-dasharray': rlen,
          'stroke-dashoffset': rlen * (1 - percentTime / 100)
        });
    
        if (percentTime >= 100) {
          $slick.slick('slickNext');
          startProgressbar();
        }
     
      }
      
      function resetProgressbar() {
        $bar.css({
         width: 0+'%' 
        });
        clearTimeout(tick);
      }
      startProgressbar();
    // circle bar
   
    $('.hero-slider .slick-arrow').on('click', function(){
      // console.log(nextSlide);
        startProgressbar(); 
    });
  }

  timelistSlider = () => {
    $(".timeline-slider").slick({
      infinite: true,
      arrows: false,
      dots: true,
      autoplay: false,
      speed: 800,
      autoplaySpeed: 6000,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade:true,
      customPaging : function(slider, i) {
        var subtitle = $(slider.$slides[i].innerHTML).find('div.postcard').data('subtitle');
        var title = $(slider.$slides[i].innerHTML).find('div.postcard').data('title');
        return `
          <div class="item">
          <span>${subtitle}</span>
          <h4>${title}</h4>
          </div>
        `;
    },
    });
    
     //ticking machine
        var percentTime;
        var tick;
        var time = .1;
        var progressBarIndex = 0;
    
        $('.progressBarContainer .progressBar').each(function(index) {
            var progress = "<div class='inProgress inProgress" + index + "'></div>";
            $(this).html(progress);
        });
    
        function startProgressbar() {
            resetProgressbar();
            percentTime = 0;
            tick = setInterval(interval, 20);
        }
    
        function interval() {
            if (($('.timeline-slider .slick-track div[data-slick-index="' + progressBarIndex + '"]').attr("aria-hidden")) === "true") {
                progressBarIndex = $('.timeline-slider .slick-track div[aria-hidden="false"]').data("slickIndex");
                startProgressbar();
            } else {
                percentTime += 1 / (time + 5);
                $('.inProgress' + progressBarIndex).css({
                    width: percentTime + "%"
                });
                if (percentTime >= 100) {
                    $('.single-item').slick('slickNext');
                    progressBarIndex++;
                    if (progressBarIndex > 2) {
                        progressBarIndex = 0;
                    }
                    startProgressbar();
                }
            }
        }
    
        function resetProgressbar() {
            $('.inProgress').css({
                width: 0 + '%'
            });
            clearInterval(tick);
        }
        startProgressbar();
        // End ticking machine
    
        $('.item').click(function () {
          clearInterval(tick);
          var goToThisIndex = $(this).find("span").data("slickIndex");
          $('.single-item').slick('slickGoTo', goToThisIndex, false);
          startProgressbar();
        });
  }

  mouseMoveMax = () => {
    var timeout;
    $('.hero-slider').mousemove(function(e){
      if(timeout) clearTimeout(timeout);
      setTimeout(callParallax.bind(null, e), 200);
      
    });

    function callParallax(e){
      parallaxIt(e, '.floL', -80);
      parallaxIt(e, '.fimages .floR', -100);
      parallaxIt(e, '.fimages .slideMainImg2', 50);
      parallaxIt(e, '.fimages .fl5', 50);
     // parallaxIt(e, '.plate', 50);
     // parallaxIt(e, '.spoon1', -50);
     // parallaxIt(e, '.spoon2', -20);
    }

    function parallaxIt(e, target, movement){
      var $this = $('.hero-slider');
      var relX = e.pageX - $this.offset().left;
      var relY = e.pageY - $this.offset().top;
      
      TweenMax.to(target, 1, {
        x: (relX - $this.width()/2) / $this.width() * movement,
        y: (relY - $this.height()/2) / $this.height() * movement,
        ease: Power2.easeOut
      })
    }

    $('.our-range').mousemove(function(e){
      if(timeout) clearTimeout(timeout);
      setTimeout(callParallax2.bind(null, e), 200);
      
    });

    function callParallax2(e){
      
     // parallaxIt2(e, '.plate', 40);
     // parallaxIt2(e, '.spoon1', -50);
     // parallaxIt2(e, '.spoon2', -80);
      parallaxIt2(e, '.rf1', -100);
      parallaxIt2(e, '.rf2', -120);
      parallaxIt2(e, '.rf3', -90);
      parallaxIt2(e, '.rf4', 50);
      parallaxIt2(e, '.rf5', 120);
    }

    function parallaxIt2(e, target, movement){
      var $this = $('.our-range');
      var relX = e.pageX - $this.offset().left;
      var relY = e.pageY - $this.offset().top;
      
      TweenMax.to(target, 1, {
        x: (relX - $this.width()/2) / $this.width() * movement,
        y: (relY - $this.height()/2) / $this.height() * movement,
        ease: Power2.easeOut
      })
    }

  }

  productDetailSlider = () => {
    if( $(".product-slider").length > 0) {
      $(".product-slider").slick({
        infinite: true,
        arrows: false,
        dots: true,
        autoplay: false,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade:true,
      });
    }

    // $("input[type='checkbox']").click(function(){
    //   var favorite = [];
    //   $.each($(".stock li input[type='checkbox']:checked"), function(){
    //       favorite.push($(this).val());
    //   });
    //   var qty = favorite.join(", ");
    //   $('.size-selected').text("Quantity:"+qty)
    //   $('#quantity-24').val(qty);
    // });
  }

  clientSlider = () => {
    if( $(".client-slider").length > 0) {
      $(".client-slider").slick({
        infinite: false,
        arrows: false,
        dots: false,
        autoplay: false,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
                arrows: false,
                dots: false,
                autoplay: true,
            },
          },
 
        ],
      });
    }
  }

  initComponents = () => {
    new Header({
      header: this.header,
      htmlBody: this.htmlBody,
    });

    if (this.mapContainer.length) {
      new Maps({
        mapContainer: this.mapContainer,
      });
    }
  };

  setDomMap = () => {
    this.window = $(window);
    this.htmlNbody = $('body, html');
    this.html = $('html');
    this.htmlBody = $('body');
    this.siteLoader = $('.site-loader');
    this.header = $('header');
    this.siteBody = $('.site-body');
    this.footer = $('footer');
    this.gotoTop = $('#gotoTop');
    this.gRecaptcha = $('.g-recaptcha');
    this.wrapper = $('.wrapper');
    this.pushDiv = this.wrapper.find('.push');
    this.mapContainer = $('#map_canvas');
    this.inputs = $('input, textarea').not('[type="checkbox"], [type="radio"]');
  };

  megaMenu = () => {
     $(".nav li.nav-dropdown").append('<span class="dropdown-icon"/>');
      if($(window).width() > 787) {
      $(".nav li").mouseenter(function() {
          if($(this).hasClass('Products')) {

           let timer = setTimeout(function(){
              $('body').addClass('showmenu');
             // $(this).find('.dropdown-icon').addClass('current');
            },400)
          
          } else {
            $('body').stop().removeClass('showmenu');
          //  $('.nav li .dropdown-icon').removeClass('current');
          }
      
      }).mouseleave(function(){
        clearTimeout(timer);
    });
      
      $(".overlay, body").mouseenter(function() {
        if(!$('.menu-container').is(':hover')){
          $('body').stop().removeClass('showmenu');
        };
      });

      // tab menu
      $('.menus .cats li a').on('mouseenter',function(){
        $('.menus .cats li a').removeClass('active');
        $(this).addClass('active');

        let menuId = $(this).data('id');

        // console.log(menuId);
        $('.cat-link ').removeClass('active');
        $(menuId).addClass('active');
      })
    } else {
      $(".nav li.nav-dropdown .dropdown-icon").on('click',function(e) {
        if($(this).parent('li').hasClass('current')){
          $(this).parent('li').removeClass('current');
          $(this).parent('li').find('ul').slideUp().removeClass('active');
        } else {
          $(this).parent('li').addClass('current');
          $(this).parent('li').find('ul').slideDown().addClass('active');
        }
       
      })
    }
  }

  productAccordian = () => {
    const accordion = $('.p-accordian');
    if(accordion.length) {
      $('.accord').first().find('.accord-title').addClass('active').next('.accord-text').css('display','block');
      // $('.accord').find('.accord-title').on('click',function(){
      //   if(!$(this).parent('.accord').is('.active')) 
      //   $('.accord').removeClass('active');
      //   $(this).parent('.accord').addClass('active');
      // })

      $('.accord-title').click(function(j) {
    
        var dropDown = $(this).closest('.accord').find('.accord-text');
        $(this).closest('.p-accordian').find('.accord-text').not(dropDown).slideUp();
        
        if ($(this).hasClass('active')) {
         // $(this).closest('.p-accordian').find('.accord-text').removeClass('active');
          $(this).removeClass('active');
          
        } else {
          $(this).closest('.p-accordian').find('.accord-title').removeClass('active');
          $(this).addClass('active');
        }
        dropDown.stop(false, true).slideToggle();
        j.preventDefault();
      });
    }
  }

  productSlider = () => {

          const timerS = 8000;
          const isSafari = /^((?!chrome).)*safari/i.test(navigator.userAgent);
          const content = {
            init: true,
            loop: true,
            speed: 900,
            spaceBetween: 50,
            slidesPerView:1,
            autoplaySpeed: 8000,
           pagination: {
            el: '.swiper-pagination',
            clickable: true,
            renderBullet: function (index, className) {
              return '<span class="' + className + '">0' + (index + 1) + '</span>';
            },
          },
          fadeEffect: {
            crossFade: true
          },
          effect: 'fade',
            autoplay: {
              enabled: true,
              disableOnInteraction: false,
              delay: timerS
            },
          };
          const image = {
            init: true,
            loop: true,
            speed: 900,
            autoplaySpeed: 8000,
            slidesPerView:1,
            spaceBetween:40,
          //  effect: isSafari ? "slide" : "fade",
            addSlidesBefore: 10,
            addSlidesAfter: 10,
            autoplay: {
              enabled: true,
              disableOnInteraction: false,
              delay: timerS
            },
          };
      if($('.pscont').length > 0 ){
          var contentTabs = new Swiper('.pscont',content);
        
        var contentBlocks = new Swiper('.psimg',image);
        
        contentTabs.controller.control = contentBlocks;
        contentBlocks.controller.control = contentTabs;
      }
      
  }

  bindEvents = () => {
    $(document).ready(function() {
      $(".enquire").fancybox();
    });
    // Window Events
    this.window.resize(this.windowResize).scroll(this.windowScroll);

    // General Events
    const $container = this.wrapper;

    $container.on('click', '.disabled', () => false);

    // Specific Events
    this.gotoTop.on('click', () => {
      this.htmlNbody.animate({
        scrollTop: 0,
      });
    });

    this.inputs
      .on({
        focus: e => {
          const self = $(e.currentTarget);
          self.closest('.element').addClass('active');
        },
        blur: e => {
          const self = $(e.currentTarget);
          if (self.val() !== '') {
            self.closest('.element').addClass('active');
          } else {
            self.closest('.element').removeClass('active');
          }
        },
      })
      .trigger('blur');

    // Reload the current path when changing language instead of redirecting to landing page
    // Uncomment below and modify languages
    // $container.on('click', '.language-toggle', function(e) {
    //   e.preventDefault();
    //   const $this = $(this);
    //   const href = $this.attr('href');
    //   const isEnglish = href.indexOf('/ar') >= 0;
    //   const locArray = location.pathname.split('/');
    //   const indexOfIndex = locArray.indexOf('index.php');
    //   const isDev = indexOfIndex >= 0;
    //   const index = isDev ? indexOfIndex + 1 : 1;
    //   if(!isEnglish) {
    //     locArray = locArray.filter(item => item !== 'ar')
    //   }
    //   locArray.splice(index, 0, isEnglish ? 'ar' : '');
    //   const newHref = locArray.join('/').replace('//', '/');
    //   location.href = newHref;
    // });

    // Uncomment below if you need to add google captcha (also in includes/script.php)
    // => Make sure the SITEKEY is changed below
    // this.gRecaptcha.each((index, el) => {
    //   grecaptcha.render(el, {'sitekey' : '6LeB3QwUAAAAADQMo87RIMbq0ZnUbPShlwCPZDTv'});
    // });

     //Tel phone plugin for contact page
     if($(".ccm-input-tel").length && !$('body').hasClass('edit-mode')){

      var telInput = $(".ccm-input-tel");
      $('.ccm-input-tel').parent().parent().addClass('active');
      $(telInput).intlTelInput({
          defaultCountry: "AE",
          initialCountry: "AE",
          nationalMode: false,
          autoPlaceholder: true,
          //utilsScript: CCM_REL + '/js/utils.js',
          geoIpLookup: function(callback) {
              $.get("https://ipinfo.io", function() {}, "jsonp").always(function(resp) {
                  var countryCode = (resp && resp.country) ? resp.country : "";
                  callback(countryCode);
              });
          }
      });

      $(telInput).keypress(function (e) {
          //if the letter is not digit then display error and don't type anything
          if (e.which!= 8 && e.which!= 0 && (e.which < 48 || e.which > 57)) {
              //display error message
              return false;
          }
      });

      /*$(telInput).live("change", function() {
          $(this).addClass('focus')
          var title = $(this).closest('.intl-tel-input').find('.selected-flag').get(0);
          title = title.getAttribute('title');
          title = title.split(':');
          title = title[0] + ':';
          var value = $(this).val();
          value = value.replace(/ +/g,' ');
          var form = $(this).closest('[id^="ff_"]');
          var fullPhone = form.find('[id^="full-phone"]').get(0);
          //alert("value is: "+title + value)
          $(fullPhone).val(title + value);

      });*/

      /*$(telInput).on("countrychange", function() {
          var title = $(this).closest('.intl-tel-input').find('.selected-flag').get(0);
          title = title.getAttribute('title');
          title = title.split(':');
          title = title[0] + ':';
          var value = $(this).val();
          value = value.replace(/ +/g,' ');
          var form = $(this).closest('[id^="ff_"]');
          var fullPhone = form.find('[id^="full-phone"]').get(0);
          //alert(title + value)
          $(fullPhone).val(title + value);
          $(this).val(value);
      });*/

  }
  };

  windowResize = () => {
    this.screenWidth = this.window.width();
    this.screenHeight = this.window.height();

    // calculate footer height and assign it to wrapper and push/footer div
    if (this.pushDiv.length){
      this.footerHeight = this.footer.outerHeight();
      this.wrapper.css('margin-bottom', -this.footerHeight);
      this.pushDiv.height(this.footerHeight);
    }
  };

  windowScroll = () => {
    const topOffset = this.window.scrollTop();

    this.header.toggleClass('top', topOffset > 300);
    this.header.toggleClass('sticky', topOffset > 600);
    if (topOffset > this.previousScroll || topOffset < 500) {
      this.header.removeClass('sticky');
    } else if (topOffset < this.previousScroll) {
      this.header.addClass('sticky');
      // Additional checking so the header will not flicker
      if (topOffset > 250) {
        this.header.addClass('sticky');
      } else {
        this.header.removeClass('sticky');
      }
    }

    this.previousScroll = topOffset;
    this.gotoTop.toggleClass(
      'active',
      this.window.scrollTop() > this.screenHeight / 2,
    );
  };

  handleSplashScreen() {
    this.htmlBody.find('.logo-middle').fadeIn(500);
    this.siteLoader.delay(1500).fadeOut(500);
  }

  handleUserAgent = () => {
    // detect mobile platform
    if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
      this.htmlBody.addClass('ios-device');
    }
    if (navigator.userAgent.match(/Android/i)) {
      this.htmlBody.addClass('android-device');
    }

    // detect desktop platform
    if (navigator.appVersion.indexOf('Win') !== -1) {
      this.htmlBody.addClass('win-os');
    }
    if (navigator.appVersion.indexOf('Mac') !== -1) {
      this.htmlBody.addClass('mac-os');
    }

    // detect IE 10 and 11P
    if (
      navigator.userAgent.indexOf('MSIE') !== -1 ||
      navigator.appVersion.indexOf('Trident/') > 0
    ) {
      this.html.addClass('ie10');
    }

    // detect IE Edge
    if (/Edge\/\d./i.test(navigator.userAgent)) {
      this.html.addClass('ieEdge');
    }

    // Specifically for IE8 (for replacing svg with png images)
    if (this.html.hasClass('ie8')) {
      const imgPath = '/themes/perfecta/images/';
      $('header .logo a img,.loading-screen img').attr(
        'src',
        `${imgPath}logo.png`,
      );
    }

    // show ie overlay popup for incompatible browser
    if (this.html.hasClass('ie9')) {
      const message = $(
        '<div class="no-support"> You are using outdated browser. Please <a href="https://browsehappy.com/" target="_blank">update</a> your browser or <a href="https://browsehappy.com/" target="_blank">install</a> modern browser like Google Chrome or Firefox.<div>',
      );
      this.htmlBody.prepend(message);
    }
  };
})();
